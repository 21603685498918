import { Typography, Link as MuiLink, TypographyProps, SxProps, Stack } from '@mui/material';
import Link from 'next/link';
import Markup from './markup';
import { IconEye } from '@tabler/icons-react';
type TruncateTextProps = {
  text: string | null;
  postId?: string;
  textLength?: number;
  mentions?: any[];
  lines?: number;
  typographyVariant?: TypographyProps['variant'];
  smallIcon?: boolean;
  sx?: SxProps;
};
export const TruncateText: React.FC<TruncateTextProps> = ({
  text,
  postId,
  textLength = 250,
  mentions,
  lines = 5,
  typographyVariant = 'body1',
  smallIcon = false,
  sx
}) => {
  const postDetailPath = postId ? `/content/${postId}` : '/';
  const showMore = text ? text.length > textLength : false;
  return <>
      <Typography gutterBottom variant={typographyVariant} color="inherit" sx={{
      textDecoration: 'none',
      margin: 'auto',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: lines,
      ...sx
    }} data-sentry-element="Typography" data-sentry-source-file="TruncateText.tsx">
        <Markup mentions={mentions} data-sentry-element="Markup" data-sentry-source-file="TruncateText.tsx">{text}</Markup>
      </Typography>
      {showMore && <Link href={postDetailPath} passHref>
          <MuiLink underline="hover" component="span" sx={{
        marginLeft: '4px'
      }}>
            ... read more
          </MuiLink>
        </Link>}
    </>;
};