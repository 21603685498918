import { AnyPublicationFragment } from '@lens-protocol/client';
import { AnyMedia } from '@lens-protocol/metadata';
import { Box, CardMedia, Typography, useTheme, useMediaQuery, Stack, Avatar } from '@mui/material';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { TruncateText } from '../shared/TruncateText';
type Props = {
  post: AnyPublicationFragment;
};
export const ChildPostDetail = ({
  post
}: Props) => {
  const [text, setText] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [video, setVideo] = useState<string | null>(null);
  const [otherImages, setOtherImages] = useState<any[] | null>(null);
  const [audio, setAudio] = useState<string | null>(null);
  const [handlesMentioned, setHandlesMentioned] = useState<any[]>();
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const postDetailPath = `/content/${post.id}`;
  useEffect(() => {
    getContentPost();
    return cleanState;
  }, [refresh]);
  const cleanState = () => {
    setText(null);
    setImage(null);
    setVideo(null);
    setOtherImages(null);
    setAudio(null);
    setThumbnail(null);
  };
  const getContentPost = async () => {
    try {
      let metadata = undefined;
      if (post.__typename === 'Mirror') {
        metadata = post.mirrorOn.metadata;
      } else {
        metadata = post.metadata;
      }
      if (metadata.__typename !== 'EventMetadataV3') {
        setText(metadata.content || '');
      }
      if (metadata.__typename === 'ImageMetadataV3') {
        if ('image' in metadata.asset) {
          setImage(metadata.asset.image.optimized?.uri as string);
        }
      }
      if ('attachments' in metadata && metadata.attachments) {
        setOtherImages(metadata.attachments as unknown as AnyMedia[]);
      }
      if (metadata.__typename === 'VideoMetadataV3') {
        if ('video' in metadata.asset && metadata.asset.video.optimized?.uri) {
          let source: string = metadata.asset.video.optimized?.uri;
          setThumbnail(metadata.asset?.cover?.optimized?.uri || '');
          setVideo(source);
        }
      }
      if (metadata.__typename === 'LiveStreamMetadataV3') {
        if ('playbackURL' in metadata && metadata.playbackURL) {
          let source: string = metadata.playbackURL;
          const attachmentsThumbnail = metadata.attachments?.find((attachment: any) => attachment.__typename === 'PublicationMetadataMediaVideo');
          if (attachmentsThumbnail && 'cover' in attachmentsThumbnail) {
            setThumbnail(attachmentsThumbnail.cover?.optimized?.uri || '');
          }
          setVideo(source);
        }
      }
      if (metadata.__typename === 'AudioMetadataV3') {
        if ('audio' in metadata.asset && metadata.asset.audio.optimized?.uri) {
          setAudio(metadata.asset.audio.optimized.uri);
        }
      }
      if ('profilesMentioned' in post && post.profilesMentioned) {
        setHandlesMentioned(post.profilesMentioned);
      }
      if (!text && !image && !otherImages && !video && !audio) return;
    } catch (error) {
      console.log('Error getting post content', error);
    }
  };
  return <Link href={postDetailPath} style={{
    textDecoration: 'none',
    color: 'inherit'
  }} data-sentry-element="Link" data-sentry-component="ChildPostDetail" data-sentry-source-file="ChildPostDetail.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} border={`2px dotted ${theme.palette.divider}`} py={1} px={2} my={1} width={'100%'} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
        <Box p={1} mt={1} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
          <Link href={`/profile/${post.by.id}`} style={{
          textDecoration: 'none',
          color: 'inherit'
        }} passHref data-sentry-element="Link" data-sentry-source-file="ChildPostDetail.tsx">
            <Stack direction="row" alignItems="center" spacing={1} sx={{
            '&:hover': {
              cursor: 'pointer',
              '& .avatar': {
                filter: 'brightness(0.5)',
                transition: '0.2s'
              },
              '& .name': {
                color: theme.palette.primary.main,
                transition: '0.2s'
              },
              '& .handle': {
                color: theme.palette.grey[400],
                transition: '0.2s'
              }
            }
          }} data-sentry-element="Stack" data-sentry-source-file="ChildPostDetail.tsx">
              <Avatar className="avatar" src={post.by.metadata?.picture?.optimized?.uri} data-sentry-element="Avatar" data-sentry-source-file="ChildPostDetail.tsx" />
              <Typography variant="h6" className="name" data-sentry-element="Typography" data-sentry-source-file="ChildPostDetail.tsx">
                {post.by.metadata?.displayName}
              </Typography>
              <Typography variant="caption" className="handle" data-sentry-element="Typography" data-sentry-source-file="ChildPostDetail.tsx">
                {`@${post.by.handle?.localName}`}
              </Typography>
            </Stack>
          </Link>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
          <Box my={1} width={'100%'} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
            <TruncateText text={text} mentions={handlesMentioned} textLength={250} data-sentry-element="TruncateText" data-sentry-source-file="ChildPostDetail.tsx" />
          </Box>
          {image ? <Link href={postDetailPath}>
              <Box display={'flex'} justifyContent={'center'} sx={{
            width: '100%'
          }}>
                <CardMedia component="img" image={image} alt="lens image" width={'100%'} height={isSmScreen ? 300 : 500} sx={{
              objectFit: 'cover',
              borderRadius: '6px'
            }} />
              </Box>
            </Link> : null}
          {video ? <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
              <ReactPlayer light={<img src={thumbnail || ''} alt="Thumbnail" style={{
            width: '100%',
            height: '100%',
            borderRadius: '6px'
          }} />} url={video} controls={true} width="100%" height={isSmScreen ? 300 : 500} />
            </Box> : null}
          {audio ? <Box sx={{
          paddingTop: '1.5rem',
          height: '3.5rem',
          width: '100%'
        }}>
              <ReactPlayer url={audio} controls={true} width="100%" height="100%" />
            </Box> : null}
        </Box>
      </Box>
    </Link>;
};