// ** React, Next & Mui
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { Stack, Avatar, Box, CardMedia, Chip, IconButton, Tooltip, useTheme, Typography, Button } from '@mui/material';
import { IconAward, IconMessage2, IconRepeat, IconThumbUp } from '@tabler/icons-react';
import { formatDistance } from 'date-fns';

// ** Context
import { useLens } from '../../context/lens';
import { useModalsActions } from '@/context/modals';
import { useSession } from '@/context/session';

// ** Components
import BlankCard from '../shared/BlankCard';
import ReactPlayer from 'react-player';
import { ChildPostDetail } from '../content/ChildPostDetail';
import { TruncateText } from '../shared/TruncateText';

// ** Utils
import { AnyPublicationFragment, CommentBaseFragment, MirrorFragment, PostFragment, ProfileMentionedFragment, QuoteBaseFragment } from '@lens-protocol/client';
import { AnyMedia } from '@lens-protocol/metadata';
import { getAvatarUrl } from '../../utils';
import { NotConnectedCase } from '../modals/not-connected';
import { CollectCriteria } from '@/utils/constants/types';
interface Props {
  post: AnyPublicationFragment;
  category?: string;
}
export const FeedPostCard = ({
  post,
  category
}: Props) => {
  const theme = useTheme();
  const {
    by,
    __typename
  } = post;
  const {
    like,
    unlike,
    fetchCollectCriteria
  } = useLens();
  const {
    open
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged
  } = useSession();
  const [isMomokaPost, setIsMomokaPost] = useState<boolean>(false);
  const [text, setText] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [video, setVideo] = useState<string | null>(null);
  const [otherImages, setOtherImages] = useState<any[] | null>(null);
  const [audio, setAudio] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quotedPost, setQuotedPost] = useState<CommentBaseFragment | PostFragment | QuoteBaseFragment | null>(null);
  const [mirroredBy, setMirroredBy] = useState<string | null>(null);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [collects, setCollects] = useState(0);
  const [isCollected, setIsCollected] = useState(false);
  const [collectCriteria, setCollectCriteria] = useState<CollectCriteria | null>();
  let handlesMentioneds: ProfileMentionedFragment[] = [];
  if ('profilesMentioned' in post && post.profilesMentioned) {
    handlesMentioneds = post.profilesMentioned;
  }
  useEffect(() => {
    getContentPost();
    return cleanState;
  }, [post]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const cleanState = () => {
    setText(null);
    setImage(null);
    setVideo(null);
    setOtherImages(null);
    setAudio(null);
    setQuotedPost(null);
  };
  const getContentPost = async () => {
    try {
      setIsLoading(true);
      const collectCriteria = await fetchCollectCriteria(post.id);
      if (__typename === 'Quote') {
        const quoteData = post;
        setQuotedPost(quoteData.quoteOn);
      }
      if (__typename === 'Mirror') {
        setQuotedPost((post as MirrorFragment).mirrorOn);
        const by = (post as MirrorFragment).by.metadata?.displayName || `@${(post as MirrorFragment).by.handle?.localName}`;
        setMirroredBy(by);
      } else {
        const {
          metadata,
          stats,
          operations
        } = post;
        if (metadata.__typename !== 'EventMetadataV3') {
          setText(metadata.content || '');
        }
        if (metadata.__typename === 'ImageMetadataV3') {
          if ('image' in metadata.asset) {
            setImage(metadata.asset.image.optimized?.uri as string);
          }
        }
        if ('attachments' in metadata && metadata.attachments) {
          setOtherImages(metadata.attachments as unknown as AnyMedia[]);
        }
        if (metadata.__typename === 'VideoMetadataV3') {
          if ('video' in metadata.asset && metadata.asset.video.optimized?.uri) {
            let source: string = metadata.asset.video.optimized?.uri;
            setVideo(source);
          }
        }
        if (metadata.__typename === 'LiveStreamMetadataV3') {
          if ('playbackURL' in metadata && metadata.playbackURL) {
            let source: string = metadata.playbackURL;
            setVideo(source);
          }
        }
        if (metadata.__typename === 'AudioMetadataV3') {
          if ('audio' in metadata.asset && metadata.asset.audio.optimized?.uri) {
            setAudio(metadata.asset.audio.optimized.uri);
          }
        }
        setLikes(stats?.upvotes);
        setIsMomokaPost(Boolean(post.momoka));
        setCollects(stats?.collects);
        setCollectCriteria(collectCriteria);
        setIsCollected(operations.hasCollected.value);
        if (operations.hasUpvoted) setIsLiked(true);
        if (!text && !image && !otherImages && !video && !audio) return;
      }
    } catch (error) {
      console.log('Error getting post content', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleLikeClick = async () => {
    if (!post) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    if (!isLiked) {
      await like(post?.id);
      setIsLiked(true);
      setLikes(prev => prev + 1);
    } else {
      await unlike(post?.id);
      setIsLiked(false);
      setLikes(prev => prev - 1);
    }
  };
  const handleCommentClick = async () => {
    if (!post) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    open('comment', {
      publicationId: post.id,
      isMomokaPub: isMomokaPost
    });
  };
  const handleCollectClick = async () => {
    if (!post) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    open('collect', {
      publicationId: post.id as string,
      onSuccess: () => {
        setIsCollected(true);
        setCollects(prev => prev + 1);
      }
    });
  };

  /*************************************************
   *                    Render                     *
   *************************************************/

  const postDetailPath = post ? `/profile/post/${post.id}` : '/';
  const userProfilePath = by ? `/profile/${by.id}` : '/';
  const timeAgo = useMemo(() => {
    const createdAt = new Date(post.createdAt);
    const now = new Date();
    return formatDistance(createdAt, now);
  }, [post.createdAt]);
  const CollectButton = () => {
    const canCollect = collectCriteria?.canCollect === 'YES';
    if (isCollected) {
      return <Button disabled={isCollected} color="secondary" variant="contained" size="small" onClick={handleCollectClick}>
          Collected
        </Button>;
    } else if (canCollect) {
      return <Button disabled={!canCollect} color="secondary" variant="contained" size="small" onClick={handleCollectClick}>
          Collect
        </Button>;
    }
    return null;
  };
  return <BlankCard className="hoverCard" data-sentry-element="BlankCard" data-sentry-component="FeedPostCard" data-sentry-source-file="PostCard.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'} p={3} sx={{
      minHeight: '24rem',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        transition: 'background-color 0.3s'
      }
    }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
        {image ? <Link href={postDetailPath}>
            <CardMedia component="img" height="115" width="100%" image={image} alt="lens image" sx={{
          objectFit: 'contain',
          margin: 'auto'
        }} />
          </Link> : null}

        {video ? <Box sx={{
        position: 'relative',
        paddingTop: '56.25%',
        height: '0',
        overflow: 'hidden'
      }}>
            <ReactPlayer url={video} controls={true} width="100%" height="100%" style={{
          position: 'absolute',
          top: '0',
          left: '0'
        }} />
          </Box> : null}

        {mirroredBy ? <Box display="flex" alignItems="center" gap={0.5}>
            <IconRepeat size="18" />
            <Chip avatar={<Avatar alt={by?.handle?.localName} src={getAvatarUrl(by)} />} component={'div'} label={mirroredBy} size="small" sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }} />
            <Typography>mirrored</Typography>
          </Box> : <>
            <Box display="flex" flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
              <Link href={userProfilePath}>
                <Stack gap={0.5}>
                  <Avatar alt={by?.handle?.localName} src={getAvatarUrl(by)} />
                  <Chip label={by?.metadata?.displayName || `@${by?.handle?.localName}`} size="small" sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }} />
                </Stack>
              </Link>

              <Box overflow="hidden" gap={1}>
                <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{
              mb: 1
            }}>
                  {category ? <Chip label={category} size="small" sx={{
                fontWeight: 'normal',
                overflow: 'hidden'
              }} /> : null}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Chip label={timeAgo} size="small" sx={{
                fontWeight: 'normal',
                overflow: 'hidden'
              }} />
                </Box>
              </Box>
            </Box>

            <Link href={postDetailPath} style={{
          textDecoration: 'none',
          color: 'unset'
        }}>
              {audio ? <Box sx={{
            paddingTop: '1.5rem',
            height: '3.5rem'
          }}>
                  <ReactPlayer url={audio} controls={true} width="100%" height="100%" />
                </Box> : null}
              <Box my={{
            xs: 3,
            sm: 0.5
          }}>
                <TruncateText text={text} postId={post.id} mentions={handlesMentioneds} lines={image || video ? 2 : 4} textLength={80} />
              </Box>
            </Link>
          </>}

        {quotedPost && <ChildPostDetail post={quotedPost as AnyPublicationFragment} />}

        <CollectButton data-sentry-element="CollectButton" data-sentry-source-file="PostCard.tsx" />

        <Stack direction="row" gap={3} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
          <Stack direction="row" gap={0.5} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
            <Tooltip title="Likes" placement="top" data-sentry-element="Tooltip" data-sentry-source-file="PostCard.tsx">
              <IconButton onClick={handleLikeClick} color={isLiked ? 'primary' : 'inherit'} data-sentry-element="IconButton" data-sentry-source-file="PostCard.tsx">
                <IconThumbUp size="18" data-sentry-element="IconThumbUp" data-sentry-source-file="PostCard.tsx" />{' '}
              </IconButton>
            </Tooltip>
            {likes.toLocaleString()}
          </Stack>
          {post.__typename !== 'Mirror' && <Stack direction="row" gap={0.5} alignItems="center">
              <Tooltip title="Comments" placement="top">
                <IconButton color="inherit" onClick={handleCommentClick}>
                  <IconMessage2 size="18" />
                </IconButton>
              </Tooltip>
              {post.stats?.comments.toLocaleString()}
            </Stack>}
          {!isMomokaPost && <Stack direction="row" gap={0.5} alignItems="center">
              <Tooltip title="Collects" placement="top">
                <IconButton color={isCollected ? 'primary' : 'inherit'} onClick={handleCollectClick}>
                  <IconAward size="18" />
                </IconButton>
              </Tooltip>
              {collects.toLocaleString()}
            </Stack>}
        </Stack>
      </Box>
    </BlankCard>;
};