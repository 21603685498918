// ** React & MUI
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { Avatar, Box, Button, CardMedia, Stack, Typography, useTheme, Tooltip, Grid, useMediaQuery } from '@mui/material';

// ** Components
import BlankCard from '../shared/BlankCard';
import ReactPlayer from 'react-player';

// ** Utils & Types
import { formatLensFragmentData } from '@/utils/helpers';
import { getAvatarUrl } from '@/utils';
import { AnyMedia } from '@lens-protocol/metadata';
import { format, getUnixTime } from 'date-fns';
import { CollectActionModuleSettings, useLens } from '@/context/lens';
import { CollectFeedItem } from '@/types/custom';
import { PostFragment, ProfileMentionedFragment, PublicationOperationsFragment, TriStateValue } from '@lens-protocol/client';
import { LikeLensPost } from '../ui-components/LikeLensPost';
import { TruncateText } from '../shared/TruncateText';
import { useSession } from '@/context/session';
import { NotConnectedCase } from '../modals/not-connected';
import { useModalsActions } from '@/context/modals';
import { useAccount } from 'wagmi';
type MintedProps = {
  item: CollectFeedItem & {
    data: {
      publication: PostFragment | null;
    };
  };
  initialFollowStatus: boolean;
};
export const MintedItem = ({
  item,
  initialFollowStatus
}: MintedProps) => {
  const [text, setText] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [video, setVideo] = useState<string | null>(null);
  const [otherImages, setOtherImages] = useState<any[] | null>(null);
  const [audio, setAudio] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCollecting, setIsCollecting] = useState(false);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [collectCriteria, setCollectCriteria] = useState<{
    canCollect: TriStateValue;
    settings: CollectActionModuleSettings | undefined;
    post: PostFragment;
  } | null>(null);
  const [isCollected, setIsCollected] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [isFollowing, setIsFollowing] = useState(initialFollowStatus);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const itemBy = formatLensFragmentData(item.data.publication?.by);
  const {
    collect
  } = useLens();
  const {
    notLoggedIn
  } = useSession();
  const {
    open: openModal
  } = useModalsActions();
  const {
    isConnected
  } = useAccount();
  let handlesMentioneds: ProfileMentionedFragment[] = [];
  if (item.data.publication && 'profilesMentioned' in item.data.publication && item.data.publication?.profilesMentioned) {
    handlesMentioneds = item.data.publication.profilesMentioned;
  }
  useEffect(() => {
    if (!item.data.publication) return;
    getContentPost(item.data.publication);
  }, [item.data.publication]);
  const getContentPost = async (publication: PostFragment) => {
    if (!publication) return;
    try {
      setIsLoading(true);
      const {
        metadata,
        operations,
        openActionModules
      } = publication;
      if (metadata.__typename === 'TextOnlyMetadataV3') {
        let content: string | null = metadata.content;
        setText(content || '');
      }
      if (metadata.__typename === 'ImageMetadataV3') {
        if ('content' in metadata && metadata.content) {
          setText(metadata.content);
        }
        if ('image' in metadata.asset) {
          setImage(metadata.asset.image.optimized?.uri as string);
        }
      }
      if ('attachments' in metadata && metadata.attachments) {
        setOtherImages(metadata.attachments as unknown as AnyMedia[]);
      }
      if (metadata.__typename === 'VideoMetadataV3') {
        if ('content' in metadata && metadata.content) {
          setText(metadata.content);
        }
        if ('video' in metadata.asset && metadata.asset.video.optimized?.uri) {
          let source: string = metadata.asset.video.optimized?.uri;
          setThumbnail(metadata.asset?.cover?.optimized?.uri || '');
          setVideo(source);
        }
      }
      if (metadata.__typename === 'LiveStreamMetadataV3') {
        if ('content' in metadata && metadata.content) {
          setText(metadata.content);
        }
        if ('playbackURL' in metadata && metadata.playbackURL) {
          let source: string = metadata.playbackURL;
          const createdAt = new Date(metadata.startsAt);
          const liveDate = format(createdAt, 'dd/MM/yy hh:mm');
          const attachmentsThumbnail = metadata.attachments?.find((attachment: any) => attachment.__typename === 'PublicationMetadataMediaVideo');
          if (attachmentsThumbnail && 'cover' in attachmentsThumbnail) {
            setThumbnail(attachmentsThumbnail?.cover?.optimized?.uri || '');
          }
          setVideo(source);
        }
      }
      if (metadata.__typename === 'AudioMetadataV3') {
        if ('content' in metadata && metadata.content) {
          setText(metadata.content);
        }
        if ('audio' in metadata.asset && metadata.asset.audio.optimized?.uri) {
          setAudio(metadata.asset.audio.optimized.uri);
        }
      }
      const settings = openActionModules.find((module: any): module is CollectActionModuleSettings => ['SimpleCollectOpenActionModule', 'MultirecipientFeeCollectOpenActionModule'].includes(module.type));
      if (!settings) return;
      const checked = checkCanCollect(settings, operations);
      const checkedCanCollect = checked ? TriStateValue.Yes : TriStateValue.No;
      setCollectCriteria({
        canCollect: isConnected ? checkedCanCollect : TriStateValue.Yes,
        settings,
        post: publication
      });
      setIsCollected(isConnected ? Boolean(operations.hasCollected.value) : false);
      if (!text && !image && !otherImages && !video && !audio) return;
    } catch (error) {
      console.log('Error getting post content', error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkCanCollect = (collectSettings: CollectActionModuleSettings, operations: PublicationOperationsFragment) => {
    // check if its expired
    if (collectSettings?.endsAt) {
      const unixEndsAt = getUnixTime(collectSettings.endsAt as any);
      return Boolean(unixEndsAt < Date.now());
    }

    // check followers only
    if (collectSettings.followerOnly && !isFollowing) {
      return false;
    }

    // if it has a collect limit and cannot collect
    // this can be optimized with opensea api obtaining the collected count
    if (collectSettings.collectLimit && operations.canCollect.valueOf() === TriStateValue.No) {
      return false;
    }
    return true;
  };
  const handleCollect = async () => {
    if (notLoggedIn) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    setIsCollecting(true);
    try {
      if (!item.data.publication) {
        throw new Error('Publication not found');
      }
      const response = await collect(item.data.publication.id);
      if (response?.isSuccess) {
        setIsCollected(true);
      }
    } catch (error) {
      console.log('Error collecting publication', error);
    } finally {
      setIsCollecting(false);
    }
  };
  const postDetailPath = item ? `/content/${item.data.publicationId}` : '/';
  const userProfilePath = itemBy ? `/profile/${itemBy.profileId}` : '/';
  const uniqueCollectors = useMemo(() => {
    const uniqueIds = new Set();
    return item.profiles.collectors?.filter(collector => {
      if (!uniqueIds.has(collector.id)) {
        uniqueIds.add(collector.id);
        return true;
      }
      return false;
    }) || [];
  }, [item.profiles.collectors]);
  const singleCollector = uniqueCollectors[0];
  const firstCollectors = uniqueCollectors.slice(0, 4);
  const extraCollectors = uniqueCollectors.slice(4);
  const collectors = showMoreItems ? uniqueCollectors : firstCollectors;
  if (!item.data.publication?.id) return null;
  return <BlankCard data-sentry-element="BlankCard" data-sentry-component="MintedItem" data-sentry-source-file="MintedItem.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={{
      xs: 'center',
      sm: 'space-between'
    }} p={2} bgcolor={theme.palette.grey[200]} sx={{
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
      minHeight: '24rem'
    }} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
        <Box display={'flex'} justifyContent={{
        xs: 'start',
        sm: 'center'
      }} width={'100%'} sx={{
        backgroundColor: theme.palette.grey[300],
        padding: '0.5rem'
      }} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
          <Stack direction={'row'} alignItems={'center'} gap={1} data-sentry-element="Stack" data-sentry-source-file="MintedItem.tsx">
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'start'} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
              <Grid container justifyContent="center" spacing={{
              xs: 0.1,
              md: 0.5
            }} sx={{
              display: 'flex',
              alignItems: 'center'
            }} data-sentry-element="Grid" data-sentry-source-file="MintedItem.tsx">
                {collectors.map((collector, i) => <Grid item key={`collector-${collector.id}-${i}`}>
                    <Link href={`/profile/${collector.id}`} passHref style={{
                  textDecoration: 'none',
                  color: 'inherit'
                }}>
                      <Tooltip title={collector.handle?.localName}>
                        <Avatar src={getAvatarUrl(collector)} alt={collector.handle?.localName} sx={{
                      width: {
                        xs: 40,
                        sm: 30
                      },
                      height: {
                        xs: 40,
                        sm: 30
                      },
                      '&:hover': {
                        filter: 'brightness(0.5)',
                        transition: '0.2s',
                        border: '2px solid transparent'
                      }
                    }} />
                      </Tooltip>
                    </Link>
                  </Grid>)}
              </Grid>
              {extraCollectors.length > 0 && !showMoreItems ? <Typography variant="caption" onClick={() => setShowMoreItems(true)} sx={{
              cursor: 'pointer'
            }}>
                  {`+${extraCollectors.length} more`}
                </Typography> : null}
            </Box>
            <Typography variant="subtitle1" fontWeight={700} mb={extraCollectors.length > 0 && !showMoreItems ? '1.25rem' : 0} data-sentry-element="Typography" data-sentry-source-file="MintedItem.tsx">
              {uniqueCollectors.length === 1 ? <span className="name">
                  {singleCollector.metadata?.displayName || `@${singleCollector.handle?.localName}`}{' '}
                </span> : ''}
              <span style={{
              color: theme.palette.primary.main
            }}>
                Collected
              </span>
            </Typography>
          </Stack>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} border={`2px dotted ${theme.palette.divider}`} my={1} mb={isSmScreen ? 0.25 : 0.1} width={'100%'} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} sx={{
          backgroundColor: theme.palette.grey[100],
          padding: '0.5rem'
        }} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
            <Box my={{
            xs: 1,
            sm: 0.2
          }} width={'100%'} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
              <TruncateText text={text} postId={item.data.publicationId} lines={isSmScreen ? undefined : 2} mentions={handlesMentioneds} sx={{
              '& p': {
                marginTop: 0,
                marginBottom: 0
              }
            }} data-sentry-element="TruncateText" data-sentry-source-file="MintedItem.tsx" />
            </Box>
            {image ? <Link href={postDetailPath}>
                <Box display={'flex'} justifyContent={'center'} sx={{
              width: '100%'
            }}>
                  <CardMedia component="img" image={image} alt="lens image" width={'100%'} height={isSmScreen ? 225 : 120} sx={{
                objectFit: 'fill',
                borderRadius: '6px'
              }} />
                </Box>
              </Link> : null}
            {video ? <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
                <ReactPlayer light={<img src={thumbnail || ''} alt="Thumbnail" style={{
              width: '100%',
              height: '100%',
              borderRadius: '6px'
            }} />} url={video} controls={true} width="100%" height={isSmScreen ? 225 : 120} />
              </Box> : null}
            {audio ? <Box sx={{
            paddingTop: {
              xs: '1.5rem',
              sm: '0.5rem'
            },
            height: '3.5rem',
            width: '100%'
          }}>
                <ReactPlayer url={audio} controls={true} width="100%" height="100%" />
              </Box> : null}
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
            <LikeLensPost publicationId={item.data.publication?.id} initialLikes={collectCriteria?.post.stats.upvotes || item.data.publication?.stats.upvotes} isLiked={collectCriteria?.post.operations.hasUpvoted || item.data.publication?.operations.hasUpvoted} hideLabel sxButton={!isSmScreen ? {
            p: '3px'
          } : undefined} data-sentry-element="LikeLensPost" data-sentry-source-file="MintedItem.tsx" />
          </Box>
          <Box p={0.5} mt={0.1} data-sentry-element="Box" data-sentry-source-file="MintedItem.tsx">
            <Link href={userProfilePath} style={{
            textDecoration: 'none',
            color: 'inherit'
          }} passHref data-sentry-element="Link" data-sentry-source-file="MintedItem.tsx">
              <Stack direction="row" alignItems="center" spacing={1} sx={{
              '&:hover': {
                cursor: 'pointer',
                '& .avatar': {
                  filter: 'brightness(0.5)',
                  transition: '0.2s'
                },
                '& .name': {
                  color: theme.palette.primary.main,
                  transition: '0.2s'
                },
                '& .handle': {
                  color: theme.palette.grey[400],
                  transition: '0.2s'
                }
              }
            }} data-sentry-element="Stack" data-sentry-source-file="MintedItem.tsx">
                <Avatar className="avatar" src={getAvatarUrl(item.data.publication?.by)} sx={{
                width: {
                  xs: 40,
                  sm: 30
                },
                height: {
                  xs: 40,
                  sm: 30
                }
              }} data-sentry-element="Avatar" data-sentry-source-file="MintedItem.tsx" />
                <Typography variant="h6" className="name" textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} maxWidth={{
                xs: '100%',
                sm: '7rem'
              }} data-sentry-element="Typography" data-sentry-source-file="MintedItem.tsx">
                  {itemBy.name}
                </Typography>
                <Typography variant="caption" className="handle" textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} maxWidth={{
                xs: '100%',
                sm: '7rem'
              }} data-sentry-element="Typography" data-sentry-source-file="MintedItem.tsx">
                  {`@${itemBy.handle}`}
                </Typography>
              </Stack>
            </Link>
          </Box>
        </Box>
        {isCollected ? <Button variant="contained" size="small" disabled>
            Collected
          </Button> : <Button variant="contained" size="small" onClick={handleCollect} disabled={isCollecting || collectCriteria?.canCollect.valueOf() === TriStateValue.No}>
            {isCollecting ? 'Collecting...' : Number(collectCriteria?.settings?.amount?.value) === 0 ? 'Free Collect' : `${Number(collectCriteria?.settings?.amount?.value).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })} $${collectCriteria?.settings?.amount?.asset.symbol || ''}`}
          </Button>}
      </Box>
    </BlankCard>;
};