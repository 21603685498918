// ** React & Mui
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, styled, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';

// ** Components
import PageContainer from '../container/PageContainer';
import { FollowingItem } from './FollowingItem';
import { MintedItem } from './MintedItem';
import { PodsFeedItemCard } from './PodsItem';
import { FeedPostCard } from './PostCard';
import { ZoraFeedItemCard } from './ZoraItem';

// ** Hooks
import { useLens } from '@/context/lens';
import { useSession } from '@/context/session';
import { useBackend } from '@/context/backend';
import { useAccount } from 'wagmi';
import { usePrivy } from '@privy-io/react-auth';
import { useZora } from '@/context/zora';
import { usePods } from '@/context/pods';

// ** Utils & Types
import { CategoryType, CollectFeedItem, FeedItem, FollowFeedItem, PodMetadataType, SignInStatus } from '@/types/custom';
import { getSignInStatus, loadCredentials } from '@/utils/helpers';
import { CredentialType } from '@/types/auth/auth';
import { AnyPublicationFragment, PostFragment } from '@lens-protocol/client';
import { isDevelopMode } from '@/utils';
import CategoriesDropdownFilter from './CategoriesDropdownFilter';
import { useCategoryFilter } from '@/context/categoryFilter';
import { MintableReturn } from '@zoralabs/protocol-sdk/dist/mint/types';
type FeedPostItemType = FeedItem & {
  data: {
    publication: AnyPublicationFragment | null;
  };
};
type FeedZoraItemType = FeedItem & {
  data: {
    zoraData: MintableReturn | undefined;
    backendData: {
      collectionAddress: `0x${string}`;
      tokenId: string;
    };
  };
};
type FeedPodsItemType = FeedItem & {
  data: {
    metadata: PodMetadataType;
  };
};
export default function FeedComponent() {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [posts, setPosts] = useState<FeedItem[]>([]);
  const [cursor, setCursor] = useState<string>();
  const [followStatus, setFollowStatus] = useState<Map<string, boolean>>(new Map());
  const [hasMore, setHasMore] = useState(true);
  const [lastProfileIdForFeed, setLastProfileIdForFeed] = useState<string>();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const {
    getTokenData
  } = useZora();
  const {
    fetchPodMetadata
  } = usePods();
  const {
    getFeedPage,
    fetchCategoriesList
  } = useBackend();
  const {
    refreshNeeded
  } = useCategoryFilter();
  const {
    getPostsById,
    checkFollowStatusBulk,
    checkIsAuthenticated,
    isCheckingLocalKeys
  } = useLens();
  const {
    user
  } = useSession();
  const {
    isConnected,
    address: wagmiAddress
  } = useAccount();
  const {
    user: privyUser,
    ready,
    authenticated
  } = usePrivy();
  const address = privyUser?.wallet?.address || wagmiAddress;
  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    if (!ready) return;
    fetchFeedItems();
    return cleanFeed;
  }, [user.profileId, isConnected, ready, refreshNeeded]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const fetchFeedItems = async (cursorParam?: string) => {
    if (!ready) return;
    try {
      const signInStatus = getSignInStatus();
      const isSignedIn = signInStatus === SignInStatus.completed;
      const isAuthenticated = await checkIsAuthenticated();
      const credentials = loadCredentials(address || '', CredentialType.lens);
      if (isConnected && ready && authenticated && credentials && isSignedIn && isAuthenticated && !user.profileId) return;
      if (isCheckingLocalKeys) return;
      const feedProfileId = isConnected ? user.profileId : undefined;
      const feedData = await getFeedPage(feedProfileId, cursorParam, 15);
      if (feedData && feedData.items && feedData.items.length > 0) {
        const processedItems = await processFeedItems(feedData.items);
        if (processedItems && processedItems.length > 0) {
          if (feedProfileId !== lastProfileIdForFeed) {
            setPosts(processedItems);
          } else {
            setPosts(prevPosts => {
              return [...prevPosts, ...processedItems];
            });
          }
        }
        setCursor(feedData.nextCursor);
        setHasMore(!!feedData.nextCursor);
      } else {
        setHasMore(false);
      }
      setLastProfileIdForFeed(feedProfileId);
    } catch (error) {
      console.error('Error fetching feed items:', error);
      setHasMore(false);
    }
  };
  const fetchCategories = async () => {
    try {
      const resCategories = await fetchCategoriesList();
      if (resCategories) {
        setCategories(resCategories);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const processFeedItems = async (items: FeedItem[]): Promise<FeedItem[]> => {
    const collectItems: CollectFeedItem[] = [];
    const followItems: FollowFeedItem[] = [];
    const uniquePublicationIds = new Set<string>();
    const uniqueProfileIds = new Set<string>();
    items.forEach(item => {
      if (item.type === 'collect' && 'publicationId' in item.data) {
        collectItems.push(item);
        uniquePublicationIds.add(item.data.publicationId);
        uniqueProfileIds.add(item.profiles.collectedProfile.id);
      } else if (item.type === 'follow') {
        followItems.push(item);
        uniqueProfileIds.add(item.profiles.followedProfile.id);
      } else if (item.type === 'post') {
        uniquePublicationIds.add(item.data.publicationId);
      }
    });
    const followStatus = await checkFollowStatusBulk(Array.from(uniqueProfileIds));
    setFollowStatus(new Map(followStatus?.map(status => [status.profileId, status.status.value])));
    let publicationsMap = new Map<string, AnyPublicationFragment>();
    const uniquePublicationIdsArray = Array.from(uniquePublicationIds);
    if (uniquePublicationIdsArray.length) {
      const publicationsData = await getPostsById(uniquePublicationIdsArray);
      publicationsMap = new Map(publicationsData.items.map(pub => [pub.id, pub]));
    }
    const processedItems = items.map(async item => {
      if ((item.type === 'collect' || item.type === 'post') && 'publicationId' in item.data) {
        return {
          ...item,
          data: {
            ...item.data,
            publication: publicationsMap.get(item.data.publicationId) || null
          }
        } as FeedPostItemType;
      } else if (item.type === 'zora-mint') {
        const tokenContract = item.data.collectionAddress as `0x${string}`;
        const tokenData = await getTokenData(tokenContract, item.data.tokenId);
        return {
          ...item,
          data: {
            zoraData: tokenData,
            backendData: item.data
          }
        } as FeedZoraItemType;
      } else if (item.type === 'pods-mint') {
        const metadata = await fetchPodMetadata(item.data.metadataURI);
        return {
          ...item,
          data: {
            ...item.data,
            metadata
          }
        } as FeedPodsItemType;
      }
      return item;
    });
    const feedItems = await Promise.all(processedItems);
    const existingItems = feedItems.filter(item => item.type === 'collect' || item.type === 'follow' || item.type === 'post' && item.data.publication || item.type === 'pods-mint' || item.type === 'zora-mint');
    return existingItems;
  };
  const handleLoadMore = () => {
    if (hasMore) {
      fetchFeedItems(cursor);
    }
  };
  const cleanFeed = () => {
    setPosts([]);
    setCursor(undefined);
    setHasMore(true);
    setLastProfileIdForFeed(undefined);
  };

  /*************************************************
   *                   Render                      *
   *************************************************/

  const CustomSwitch = styled((props: any) => <Switch {...props} />)(({
    theme
  }) => ({
    '&.MuiSwitch-root': {
      width: '68px',
      height: '49px'
    },
    '&  .MuiButtonBase-root': {
      top: '6px',
      left: '6px'
    },
    '&  .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb': {
      backgroundColor: 'primary.main'
    },
    '& .MuiSwitch-thumb': {
      width: '18px',
      height: '18px',
      borderRadius: '6px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
      borderRadius: '5px'
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: 'primary',
          opacity: 0.18
        }
      }
    }
  }));
  return <PageContainer data-sentry-element="PageContainer" data-sentry-component="FeedComponent" data-sentry-source-file="Feed.tsx">
      <Box display="flex" flexDirection="column" alignItems="center" sx={{
      ...(isDevelopMode && {
        marginBottom: '1em'
      })
    }} data-sentry-element="Box" data-sentry-source-file="Feed.tsx">
        <CategoriesDropdownFilter categories={categories} data-sentry-element="CategoriesDropdownFilter" data-sentry-source-file="Feed.tsx" />
      </Box>
      <Grid container gap={3} display="flex" direction={isMdScreen ? 'column' : 'row'} data-sentry-element="Grid" data-sentry-source-file="Feed.tsx">
        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="Feed.tsx">
          <InfiniteScroll dataLength={posts.length} next={handleLoadMore} hasMore={hasMore} loader={<Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10vh'
        }}>
                <CircularProgress />
              </Box>} endMessage={<Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10vh'
        }}>
                <Typography>No more posts to load</Typography>
              </Box>} scrollThreshold="10px" style={{
          overflow: 'visible'
        }} data-sentry-element="InfiniteScroll" data-sentry-source-file="Feed.tsx">
            <Grid container justifyContent="center" spacing={3} data-sentry-element="Grid" data-sentry-source-file="Feed.tsx">
              {posts.map((post, index) => <Grid key={`${post.type}-${post.id}-${index}`} item xs={12} sm={6} md={4}>
                  {post.type === 'follow' ? <FollowingItem item={post} initialFollowStatus={isConnected ? Boolean(followStatus.get(post.profiles.followedProfile.id)) : false} /> : post.type === 'collect' ? <MintedItem item={post as CollectFeedItem & {
                data: {
                  publication: PostFragment | null;
                };
              }} initialFollowStatus={isConnected ? Boolean(followStatus.get(post.profiles.collectedProfile?.id)) : false} /> : post.type === 'post' && post.data.publication ? <FeedPostCard post={post.data.publication} category={post.data.category} /> : post.type === 'zora-mint' ? <ZoraFeedItemCard token={post.data} collectors={post.profiles.collectors} /> : post.type === 'pods-mint' ? <PodsFeedItemCard podId={post.data.podId} tokenId={post.data.tokenId} metadataURI={post.data.metadataURI} collectors={post.profiles.collectors} metadata={post.data.metadata as PodMetadataType} /> : null}
                </Grid>)}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Grid>
    </PageContainer>;
}